import React, { createContext, useContext } from "react";

type CreateScopedContextReturn<TProps> = readonly [Context: React.Context<TProps>, useContext: () => TProps];

export function createScopedContext<TProps extends Record<string, unknown>>(
    name: string
): CreateScopedContextReturn<TProps> {
    const Context = createContext<TProps | null>(null) as React.Context<TProps>;

    const useHook = () => {
        const ctx = useContext(Context);
        if (!ctx) {
            throw new Error(`use${name} must be used inside a <${name}.Provider>`);
        }

        return ctx;
    };

    return [Context, useHook];
}
