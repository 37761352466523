import React from "react";
import useResizeObserver from "use-resize-observer";
import { useFaCollapsibleContext } from "../FaCollapsibleContext/FaCollapsibleContext";
import "./faCollapsibleBody.scss";

interface IFaCollapsibleBodyProps {
    children: React.ReactNode;
}

export function FaCollapsibleBody(props: IFaCollapsibleBodyProps) {
    const { open } = useFaCollapsibleContext();

    const { ref, height = 0 } = useResizeObserver<HTMLDivElement>({
        box: "border-box",
    });

    const styles: React.CSSProperties = {
        height: open ? height : 0,
        opacity: open ? 1 : 0,
    };

    return (
        <div className="fa-collapsible-body" style={styles}>
            <div ref={ref} className="fa-collapsible-body__container">
                {props.children}
            </div>
        </div>
    );
}
