import { createScopedContext } from "../../../util/context";

type IFaCollapsibleContextProps = {
    disabled: boolean;
    //
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const [FaCollapsibleContext, useFaCollapsibleContext] =
    createScopedContext<IFaCollapsibleContextProps>("FaCollapsibleContext");
