import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FaCollapsibleContext } from "../FaCollapsibleContext/FaCollapsibleContext";

interface IFaCollapsibleRootProps {
    disabled?: boolean;
    children: React.ReactNode;
}

export interface IFaCollapsibleRef {
    open: () => void;
    close: () => void;
}

export const FaCollapsibleRoot = forwardRef<IFaCollapsibleRef, IFaCollapsibleRootProps>((props, forwardedRef) => {
    const { disabled = false, children } = props;

    const [open, setOpen] = useState(false);

    useImperativeHandle(
        forwardedRef,
        () => ({
            open: () => setOpen(true),
            close: () => setOpen(false),
        }),
        [setOpen]
    );

    return (
        <FaCollapsibleContext.Provider value={{ disabled, open, setOpen }}>{children}</FaCollapsibleContext.Provider>
    );
});
