import { FaCollapsibleRoot, IFaCollapsibleRef } from "./FaCollapsibleRoot/FaCollapsibleRoot";
import { FaCollapsibleTrigger } from "./FaCollapsibleTrigger/FaCollapsibleTrigger";
import { FaCollapsibleIcon } from "./FaCollapsibleIcon/FaCollapsibleIcon";
import { FaCollapsibleBody } from "./FaCollapsibleBody/FaCollapsibleBody";

export const Root = FaCollapsibleRoot;
export type Ref = IFaCollapsibleRef;
export const Trigger = FaCollapsibleTrigger;
export const Icon = FaCollapsibleIcon;
export const Body = FaCollapsibleBody;
